.mobileNav {
  display: none;
  @media (max-width: $layout-breakpoint-medium) {
    display: block;
    flex: 1;
  }
}

.header {
  font-size: 1.4rem;
  height: 120px;
  display: flex;
  justify-content: center;
  padding: 0 108px;
  color: $pink;

  position: fixed;
  width: 100%;
  z-index: 2;

  @media (max-width: $layout-breakpoint-medium) {
    padding: 0 72px;
    font-size: 1rem;
    background: $black;
  }
  @media (max-width: $layout-breakpoint-xsmall) {
    padding: 0 12px;
    height: 96px;
    font-size: 1rem;
  }
  .header-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      font-weight: 700;
      font-size: 2.6rem;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 2.2rem;
        flex: 4;
      }
    }
    .nav {
      @media (max-width: $layout-breakpoint-medium) {
        display: none;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      li {
        list-style: none;
        margin: 0 40px;
        a {
          text-decoration: none;
          // color: $pink;
          white-space: nowrap;
        }
      }
    }

    .book-now {
      // display: none;
      @media (max-width: $layout-breakpoint-medium) {
        display: block;
        flex: 1;
      }
      div {
        text-decoration: none;
        border-bottom: 2px solid $pink;
        padding-bottom: 4px;
        white-space: nowrap;

        @media (max-width: $layout-breakpoint-xsmall) {
          padding-bottom: 4px;
        }
      }
    }
    .hamburger-menu {
      height: 56px;
      width: 56px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      cursor: pointer;
      &:hover {
        span {
          background: $lavender;
        }
      }

      @media (min-width: $layout-breakpoint-medium) {
        display: none;
      }
      span {
        width: 20px;
        height: 3px;
        margin: 2px 0;
        background: $pink;
        display: block;
      }
    }
  }
}
