@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: 'Saol';
  src: url('../../fonts/SaolDisplay-LightItalic.woff') format('woff'),
    url('../../fonts/SaolDisplay-LightItalic.woff2') format('woff2');
  font-style: normal;
}

html {
  font-size: 16px;
  line-height: 1.25;
  body {
    // font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    //   Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    //   sans-serif;
    font-family: forma-djr-display, sans-serif;
    font-weight: 200;
    font-style: normal;

    .saol {
      font-family: 'Saol', serif;
    }
  }
}

p,
span {
  font-size: 1.4rem;
}

a {
  font-weight: 600;
}
