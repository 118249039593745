$move-initial: calc(-25%);
$move-final: calc(-50%);

.marquee {
  position: relative;
  overflow: hidden;
  &.animate .marquee__inner {
    animation-play-state: running;
  }
  .marquee__inner {
    width: fit-content;
    display: flex;
    position: relative;
    transform: translate3d($move-initial, 0, 0);
    animation: marquee 5s linear infinite;
    animation-play-state: paused;
    color: $lavender;
    @media (min-width: $layout-breakpoint-large) {
      transform: translate3d(calc(-25% + 10vw), 0, 0);
      animation: marquee-lg 5s linear infinite;
      animation-play-state: paused;
    }
  }
  .row-title {
    padding: 0 8vw;
    @media (min-width: $layout-breakpoint-large) {
      padding: 0 10vw;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translate3d($move-initial, 0, 0);
  }

  100% {
    transform: translate3d($move-final, 0, 0);
  }
}

@keyframes marquee-lg {
  0% {
    transform: translate3d(calc(-25% + 10vw), 0, 0);
  }

  100% {
    transform: translate3d(calc(-50% + 10vw), 0, 0);
  }
}
