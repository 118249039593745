@import 'abstracts/variables';
@import 'base/global';
@import 'base/reset';
@import 'base/typography';

@import 'components/header';
@import 'components/banner';
@import 'components/marquee';
@import 'components/loader';
@import 'components/container';
@import 'components/large-text-block';
@import 'components/text-with-image';
@import 'components/social-follow';
@import 'components/carousel';

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
