.carousel {
  .img-container {
    width: 1200px;
    height: 800px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
