.text-with-image {
  position: relative;
  .text-with-image--img {
    height: 530px;
  }
  .text-with-image--content {
    margin: 0 auto;
    max-width: 415px;
    padding: 7rem 4rem 6rem 4rem;
    a {
      padding: 0;
      color: $green;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
