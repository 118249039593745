// Breakpoints
$layout-breakpoint-large: 1920px;
$layout-breakpoint-medium: 1367px;
$layout-breakpoint-small: 960px;
$layout-breakpoint-xsmall: 480px;

// Colors
$pink: #ffcad4;
$green: #819b7f;
$lavender: #e4b7f6;
$white: #fff;
$black: #000000;
