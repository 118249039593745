.bg-pink {
  background-color: var(--pink);
}

.bg-green {
  background-color: var(--green);
}
.bg-lavender {
  background-color: var(--lavender);
}

.pink {
  color: var(--pink);
}
.text-green,
.hover-green:hover {
  color: var(--green);
}

.b--lavender {
  border-color: var(--lavender);
}
.text-lavender,
.hover-lavender:hover {
  color: var(--lavender);
}
.mx-auto {
  margin: 0 auto;
}
.top-container {
  height: calc(100vh - 5rem);
}

.menu {
  min-height: 4.5rem; /* no way to control min height in Tachyons */
  max-height: 0; /* hide menu completely when burger unchecked */
  transition: max-height 0.5s; /* show/hide menu transition */
}
/* when checkbox is checked; display menu (sibling element) */
#burger:checked ~ .menu {
  max-height: 100%;
}

:root {
  --white: #ffffff;
  --black: #000000;
  --pink: #ffcad4;
  --lavender: #e4b7f6;
  --green: #819b7f;
}
