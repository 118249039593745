.large-text-block {
  p {
    font-size: inherit;
    margin-bottom: 2rem;
  }
  a {
    padding: 0;
    color: $lavender;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
