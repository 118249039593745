.banner {
  z-index: 1;
  position: relative;
  color: $pink;
  padding-top: 156px;
  &.portraits {
    padding-top: 0vmin;
  }
  @media (max-width: $layout-breakpoint-xsmall) {
    padding-top: 76px;
  }
  .banner-row {
    overflow: hidden;
    display: flex;
    align-items: center;
    &.portraits {
      height: 10vmin;
    }
    @media (max-width: $layout-breakpoint-medium) {
      &:first-child {
        margin-top: 48px;
      }
    }
    &.center {
      justify-content: space-around;
      text-align: center;
      .row-letter {
        left: 0;
      }
    }
    &.right {
      justify-content: flex-end;
    }
    .row-title,
    .row-letter {
      font-size: 18rem;
      font-weight: 300;
      position: relative;
      letter-spacing: -0.25rem;
      display: inline-block;
      white-space: nowrap;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 12rem;
      }
      @media (max-width: $layout-breakpoint-xsmall) {
        font-size: 8rem;
      }
      &.portraits {
        font-size: 6rem;
      }
    }
    .row-col {
      width: 50%;
      display: flex;
      align-content: center;
      justify-content: center;
      @media (max-width: $layout-breakpoint-medium) {
        width: 70%;
      }
      @media (max-width: 1240px) {
        &:last-child {
          display: none;
        }
      }
      @media (max-width: $layout-breakpoint-xsmall) {
        width: 100%;
        justify-content: flex-start;
      }
      .row-message {
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 2rem;
        width: 320px;
      }
    }
    .scroll {
      height: 160px;
      width: 160px;
      border-radius: 100%;
      background: $white;
      color: $black;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      margin-right: 1rem;
      @media (max-width: $layout-breakpoint-medium) {
        left: 60px;
      }
      @media (max-width: 1240px) {
        display: none;
      }
      span {
        font-size: 18px;
        font-weight: 600;
        margin: 4px 0;
        @media (max-width: $layout-breakpoint-xsmall) {
          font-size: 10px;
          margin: 2px 0;
        }
      }
    }
  }
}

.transition-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 800px;
    display: flex;
  }
  &.final {
    display: block;
    top: -128px;
    position: relative;
    width: 90%;
    margin: 0 auto;
    height: unset;
    // z-index: -100;
    @media (max-width: $layout-breakpoint-xsmall) {
      top: -56px;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
